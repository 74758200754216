<template>
  <b-card no-body>
    <loading :show="is_loading">
      <validation-observer
          #default="{ handleSubmit }"
          ref="refFormObserver"
      >
        <!-- Form -->
        <b-form
            class="p-2"
            @submit.prevent="handleSubmit(submitForm)"
        >

          <!-- Name -->
          <validation-provider
              #default="{errors}"
              name="name"
              rules="required"
          >
            <b-form-group
                :label="$i18n.t('Name')"
                label-for="name"
            >
              <b-form-input
                  id="name"
                  v-model="userData.name"
                  autofocus
                  :state="errors.length > 0 ? false:null"
                  trim
                  placeholder="Name"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Email -->
          <validation-provider
              #default="{ errors }"
              name="email"
              rules="required|email"
          >
            <b-form-group
                :label="$i18n.t('Email')"
                label-for="email"
            >
              <b-form-input
                  id="email"
                  v-model="userData.email"
                  :state="errors.length > 0 ? false:null"
                  trim
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- password -->
          <b-form-group
              :label="$i18n.t('Password')"
              label-for="password"
          >
            <validation-provider
                #default="{ errors }"
                name="password"
                vid="password"
                rules="password"
            >
              <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                    id="password"
                    v-model="userData.password"
                    :type="password1FieldType"
                    :state="errors.length > 0 ? false:null"
                    class="form-control-merge"
                    name="password"
                    placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                      class="cursor-pointer"
                      :icon="password1ToggleIcon"
                      @click="togglePassword1Visibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- confirm password -->
          <b-form-group
              label-for="password-confirm"
              :label="$i18n.t('Confirm Password')"
          >
            <validation-provider
                #default="{ errors }"
                name="password-confirm"
                rules="confirmed:password"
            >
              <b-input-group
                  class="input-group-merge"
                  :class="errors.length > 0 ? 'is-invalid':null"
              >
                <b-form-input
                    id="password-confirm"
                    v-model="userData.cPassword"
                    :type="password2FieldType"
                    class="form-control-merge"
                    :state="errors.length > 0 ? false:null"
                    name="password-confirm"
                    placeholder="············"
                />
                <b-input-group-append is-text>
                  <feather-icon
                      class="cursor-pointer"
                      :icon="password2ToggleIcon"
                      @click="togglePassword2Visibility"
                  />
                </b-input-group-append>
              </b-input-group>
              <small class="text-danger">{{ errors[0] }}</small>
            </validation-provider>
          </b-form-group>

          <!-- User Language -->
          <validation-provider
              #default="{ errors }"
              name="lang"
              rules="required"
          >
            <b-form-group
                :label="$i18n.t('Lang')"
                label-for="user-lang"
            >
              <v-select
                  v-model="userData.lang"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="available_locales"
                  :reduce="val => val"
                  :clearable="false"
                  input-id="lang"
              />
              <small class="text-danger">{{ errors[0] }}</small>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
                variant="primary"
                class="mr-2"
                type="submit"
            >
              <span>{{ $i18n.t('Update Profile') }}</span>
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </loading>
  </b-card>
</template>

<script>
import {alphaNum, email, required} from '@validations'
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

export default {
  data() {
    return {
      userData: {
        id: '',
        name: '',
        email: '',
        password: '',
        cPassword: '',
        lang: '',
      },
      available_locales: [],
      roles: [],
      required,
      alphaNum,
      email,
      // Toggle Password
      password1FieldType: 'password',
      password2FieldType: 'password',
      is_loading: false,
    }
  },
  computed: {
    password1ToggleIcon() {
      return this.password1FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    password2ToggleIcon() {
      return this.password2FieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
  },
  mounted() {
    this.getUtils()
    this.getUser()
  },
  methods: {
    getUtils() {
      this.utilsRepository().addEditUserUtils()
          .then(res => {
            this.roles = res.data.data.roles
            this.available_locales = res.data.data.available_locales
          })
    },
    getUser() {
      this.userData = JSON.parse(JSON.stringify(this.auth()))
    },
    togglePassword1Visibility() {
      this.password1FieldType = this.password1FieldType === 'password' ? 'text' : 'password'
    },
    togglePassword2Visibility() {
      this.password2FieldType = this.password2FieldType === 'password' ? 'text' : 'password'
    },
    submitForm() {
      const form = {
        name: this.userData.name,
        email: this.userData.email,
        lang: this.userData.lang
      }
      if (this.userData.password && this.userData.password !== '') {
        this.$set(form, 'password', this.userData.password)
        this.$set(form, 'password_confirmation', this.userData.cPassword)
      }
      this.is_loading = true
      this.authRepository().updateProfile(form)
          .then(response => {
            this.is_loading = false
            const user = response.data.data
            this.$store.dispatch('auth/updateProfile', {user})
            this.getUser()
            this.$toast({
              component: ToastificationContent,
              props: {
                title: this.$i18n.t('Success'),
                icon: 'EditIcon',
                variant: 'success',
            text: response.data.message
          },
        })
      }).catch(error => {
        this.is_loading = false
        if (error.data && error.data.errors) {
          this.$refs.refFormObserver.setErrors(error.data.errors)
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
